import "./App.css";
import Navbar from "./components/Navbar";
import Homepage from "./components/Homepage";

function App() {
  return (
    <>
      {/* <div className="banner">
        <p>
          <strong>
          Symetryx Letter To Check-Cap Shareholders
            <a
              href="https://www.prnewswire.com/news-releases/symetryx-comments-on-successful-campaign-at-check-cap-ltd-302021868.html"
              target="blank"
            >
              <br></br>
              Click Here
            </a>
          </strong>
        </p>
      </div> */}
      <Navbar />
      <Homepage />
    </>
  );
}

export default App;
